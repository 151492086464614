<template>
  <div class="earnings">
    <div v-if="earningsShow == 0" class="earnings-interface">
      <div class="earnings-headline">
        <!--总收益模块-->
        <div class="headline-block">
          <div class="headline-block-left">
            <div
              class="headline-block-left-div"
              v-for="(item, index) in 3"
              :key="index"
            >
              <div class="headline-block-left-div0" v-if="index == 0">
                <div class="block-left-div0-top">
                  <img
                    class="block-left-div0-top-img"
                    src="../assets/home/icon_shouyi.png"
                  />
                  <div class="block-left-div0-top-title">总收益</div>
                </div>

                <div class="block-left-div0-bottom">
                  <div class="block-left-div0-bottom-price">
                    ¥ {{ earningObj.grandPrice }}
                  </div>
                </div>
              </div>
              <div class="headline-block-left-div1" v-if="index == 1">
                <div class="block-left-div1-top">
                  <div class="block-left-div1-top-text">昨日收益</div>
                  <div class="block-left-div1-top-price">
                    ¥ {{ earningObj.yesterdayPrice }}
                  </div>
                </div>
                <div class="block-left-div1-bottom">
                  <div class="block-left-div1-bottom-left">
                    可用收益：{{ earningObj.yesterdayConsumePrice }}
                  </div>
                  <div class="block-left-div1-bottom-right">
                    锁定收益：{{ earningObj.yesterdayLockPrice }}
                  </div>
                </div>
              </div>
              <div class="headline-block-left-div2" v-if="index == 2">
                <div class="block-left-div1-top">
                  <div class="block-left-div1-top-text">今日收益</div>
                  <div class="block-left-div1-top-price">
                    ¥ {{ earningObj.todayPrice }}
                  </div>
                  <img
                    v-if="earningObj.todayPrice != earningObj.yesterdayPrice"
                    class="block-left-div1-top-rise"
                    :src="
                      earningObj.todayPrice > earningObj.yesterdayPrice
                        ? require('../assets/earnings/Path.png')
                        : earningObj.todayPrice < earningObj.yesterdayPrice
                        ? require('../assets/earnings/drop.png')
                        : ''
                    "
                  />
                </div>
                <div class="block-left-div1-bottom">
                  <div
                    class="block-left-div1-bottom-left"
                    style="color: #888888"
                  >
                    可用收益：{{ earningObj.todayConsumePrice }}
                  </div>
                  <div
                    class="block-left-div1-bottom-right"
                    style="color: #888888"
                  >
                    锁定收益：{{ earningObj.todayLockPrice }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="headline-block-right">
            <div class="headline-block-right-queer">
              <div class="block-right-queer-title">可提现收益</div>
              <div class="block-right-queer-price">
                ¥ {{ earningObj.consumePrice }}
              </div>
            </div>
            <div class="headline-block-right-dextro">
              <div class="block-right-dextro-withdraw" @click="depositMet">
                提现
              </div>
              <div class="block-right-dextro-div">
                <div class="dextro-div-usable">
                  可用收益：{{ earningObj.consumePrice }}
                </div>
                <div class="dextro-div-lock">
                  锁定收益：{{ earningObj.lockPrice }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--第二模块  列表搜索-->
        <div class="headline-third">
          <div class="headline-third-top">
            <div class="headline-third0">
              <div class="headline-third0-title">用户ID</div>
              <el-input
                v-model="userId"
                placeholder="请输入用户ID"
                clearable
              ></el-input>
            </div>
            <div class="headline-third0">
              <div class="headline-third0-title">用户手机号</div>
              <el-input
                v-model="userPhone"
                placeholder="请输入手机号"
                clearable
              ></el-input>
            </div>
            <div class="headline-third0" style="margin-right: 28px">
              <div class="headline-third0-title">订单号</div>
              <el-input
                v-model="userOrder"
                placeholder="请输入订单号"
                clearable
              ></el-input>
            </div>

            <div class="headline-third0" style="margin-right: 28px">
              <div class="headline-third0-title">收益状态</div>
              <el-select v-model="orderValue" placeholder="收益状态" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div class="headline-third1">
              <div class="headline-third1-title">确认收货时间</div>
              <el-date-picker
                v-model="receiptValue"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="headline-third-bottom">
            <div class="headline-third1-bottom" @click="submitBtn">查询</div>
          </div>
        </div>

        <!--第三模块  列表表格-->
        <div class="headline-table">
          <el-table
            :data="eamingObj.orderList"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              background: '#FAFAFA ',
            }"
          >
            <el-table-column prop="uid" label="用户id" align="center">
            </el-table-column>
            <el-table-column prop="phone" label="用户手机号" align="center">
            </el-table-column>
            <el-table-column prop="ordernum" label="用户订单号" align="center">
            </el-table-column>
            <el-table-column prop="payTime" label="用户付款时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.payTime | formatDate }}
              </template>
            </el-table-column>
            <el-table-column prop="price" label="收益面值" align="center">
              <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
            </el-table-column>
            <el-table-column label="收益状态" align="center">
              <template slot-scope="scope">
                <div
                  class="earningStatusColor"
                  :style="{
                    color: scope.row.status == 'ENTER' ? '#f9b63c' : '#666666',
                  }"
                >
                  {{
                    scope.row.status == 'ENTER'
                      ? '已入账'
                      : scope.row.status == 'WAIT'
                      ? '待入账'
                      : scope.row.status == 'PAST'
                      ? '已过期'
                      : ''
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--第四模块  分页模块-->
        <div class="headline-paging" v-if="totalNum > 10">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="10"
            layout="prev, pager, next, sizes, jumper"
            :total="totalNum"
            :pager-count="11"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <div v-if="earningsShow == 1" class="earnings-deposit">
      <div class="deposit-first">
        <div class="first-right">
          <div class="right-sign">1</div>
          <div class="right-title">填写提现金额</div>

          <div class="right-lint">
            <img src="../assets/you.png" alt="" />
          </div>

          <div
            class="right-sign"
            :style="{ opacity: depositplayShow < 1 ? '0.3' : '1' }"
          >
            2
          </div>
          <div
            class="right-title"
            :style="{ opacity: depositplayShow < 1 ? '0.3' : '1' }"
          >
            提交审核
          </div>
        </div>
      </div>

      <div class="deposit-second">
        <div class="second-name">
          <div class="name" style="color: #666666"><span>*</span>提现金额</div>
          <div>
            <div class="input">
              <el-input
                v-model="moneyPrice"
                :placeholder="returnTitle"
                @input="moneyChange"
              ></el-input>
              <div class="price">元</div>
            </div>
            <!-- <div class="text" v-if="moneyPrice.length > 0">
              {{ rateTitle }}
            </div> -->
          </div>
        </div>

        <div class="second-display">
          <div class="display-lint"></div>
          <div class="display-text">最多可提现 {{ returnPrice }}</div>
          <div class="display-text">提现手续费 {{ rateTitle }} 元</div>
          <div class="display-text">
            到账金额 ¥{{ accountPrice == '' ? 0 : accountPrice }}
          </div>
          <!-- <div class="display-text" v-if="this.depositIndex == 0">
            提现到： {{ zfbObj.bank_number }}
          </div>
          <div class="display-text" v-if="this.depositIndex == 2">
            提现到： {{ bankTitle }}
          </div> -->
        </div>

        <div class="second-name" style="align-items: flex-start">
          <div
            class="name"
            style="color: #666666; padding-top: 5px; box-sizing: border-box"
          >
            <span>*</span>选择通道
          </div>
          <div class="block">
            <div
              class="block-list"
              v-for="(item, index) in 3"
              :key="index"
              @click="depositblockMet(index)"
              :class="{ active: depositIndex == index }"
            >
              <img
                :style="{
                  width: index == 0 ? '21px' : index == 1 ? '25px' : '26px',
                  height: index == 0 ? '21px' : index == 1 ? '25px' : '16px',
                }"
                :src="
                  index == 0
                    ? require('../assets/zhifubaozhifu.png')
                    : index == 1
                    ? require('../assets/wx.png')
                    : require('../assets/bank.png')
                "
                alt=""
              />

              <div class="block-list-title">
                {{ index == 0 ? '支付宝' : index == 1 ? '微信' : '银行卡' }}
              </div>
            </div>
          </div>
        </div>

        <div class="second-bottom">
          <div class="bottom-1" @click="depositresetMet">重 置</div>
          <div
            class="bottom-2"
            :style="{ opacity: depositplayShow == 0 ? '1' : '0.3' }"
            @click="depositnextMet"
          >
            确认提现
          </div>
        </div>

        <div class="second-warning" @click="warningShow = !warningShow">
          <img
            :src="
              warningShow == false
                ? require('../assets/check-0.png')
                : require('../assets/check-1.png')
            "
            alt=""
          />

          我已经阅读并同意
          <a href="https://api.xmw1688.cn/payment.html">
            <span style="color: #29d647; margin-left: 10px">支付协议</span>
          </a>
        </div>
      </div>
    </div>

    <div v-if="earningsShow == 2" class="earnings-approve">
      <div class="approve-first">
        <div class="first-right">
          <div class="right-sign">1</div>
          <div class="right-title">填写提现金额</div>

          <div class="right-lint">
            <img src="../assets/you.png" alt="" />
          </div>

          <div
            class="right-sign"
            :style="{ opacity: depositplayShow < 1 ? '0.3' : '1' }"
          >
            2
          </div>
          <div
            class="right-title"
            :style="{ opacity: depositplayShow < 1 ? '0.3' : '1' }"
          >
            提交审核
          </div>
        </div>
      </div>

      <div class="approve-result">
        <div class="result-icon">
          <img src="../assets/daishenh.png" alt="" />
        </div>
        <div class="result-block">
          <div class="block-title">审核中，请耐心等待</div>
        </div>
      </div>

      <div class="approve-block">
        <div class="block" v-for="(item, index) in 4" :key="index">
          <div class="block-left">
            {{
              index == 0
                ? '真实姓名'
                : index == 1
                ? '提现类型'
                : index == 2
                ? '提现金额'
                : depositIndex == 0
                ? '支付宝卡号'
                : '银行卡卡号'
            }}
          </div>
          <div class="block-right">
            {{
              index == 0
                ? realName
                : index == 1
                ? depositIndex == 0
                  ? '支付宝'
                  : '银行卡'
                : index == 2
                ? moneyPrice
                : zfbObj.bank_number
            }}
          </div>
        </div>
      </div>
    </div>

    <!--用户搜索提示框-->
    <div class="earning-dialog">
      <el-dialog
        :visible.sync="dialogVisible"
        width="394px"
        :before-close="handleClose"
        center
        style="margin-top: 20vh"
      >
        <div class="earning-dialog-div">
          <img
            class="earning-dialog-div-img"
            src="../assets/earnings/warning.png"
          />
          <div class="earning-dialog-div-title">
            {{ dialodTitle }}
          </div>

          <div class="earning-dialog-div-display">
            <div class="earning-dialog-div-display-cancel" @click="cancelBtn">
              取消
            </div>
            <div class="earning-dialog-div-display-affirm" @click="affirmBtn">
              确定
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--提现弹出框-->
    <div class="depositblock">
      <el-dialog
        title="请选择银行卡"
        :visible.sync="depositdialogVisible"
        width="30%"
        :before-close="deposithandleClose"
      >
        <div class="dialog-block">
          <div
            class="block-list"
            v-for="(item, index) in bankList"
            :key="index"
            @click="depositdialogMet(item, index)"
          >
            <div class="list-left">
              <img :src="item.icon_url" alt="" />
              <div class="left-title">{{ item.bank_name }}</div>
            </div>
            <div class="list-right">
              <img
                v-if="index == depositdialogIndex"
                src="../assets/dui.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="dialog-bottom" @click="addBank">添加提现方式</div>
      </el-dialog>
    </div>

    <!--支付宝提现弹出框-->
    <div class="depositblock">
      <el-dialog
        title=""
        :visible.sync="depositdialogVisiblepay"
        width="30%"
        :before-close="dephandleClose"
      >
        <div class="block-display">
          <div class="img">
            <img src="../assets/plaint.png" alt="" />
          </div>
          <div class="title">支付宝账号：{{ zfbObj.bank_number }}</div>
          <div class="text">请确认支付宝的信息。3-7个工作日内打款</div>

          <div class="bottom">
            <div class="bottom-left" @click="depositcCancel">取消</div>
            <div class="bottom-right" @click="depositcAffirm">确认</div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 签约银行 -->
    <div class="mysign">
      <el-dialog
        title="签约银行卡"
        :visible.sync="dialogVisible8Show"
        width="566px"
        :before-close="handleClose8"
      >
        <div class="mysign-block">
          <div class="block-first">
            <div class="first-list">
              <div class="list-title">收款人姓名</div>
              <div class="list-input">
                <el-input
                  v-model="bankObjs.name"
                  placeholder="请输入开户行名称"
                  :readonly="true"
                ></el-input>
              </div>
            </div>

            <div class="first-list">
              <div class="list-title">银行卡名称</div>
              <div class="list-input">
                <el-select
                  v-model="bankValue"
                  placeholder="请选择银行卡"
                  @change="selectChange"
                >
                  <el-option
                    v-for="item in signOptions"
                    :key="item.id"
                    :value="item.bank_name"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="first-list">
              <div class="list-title">银行卡账号</div>
              <div class="list-input">
                <el-input
                  v-model="bankCard"
                  placeholder="请输入银行卡账号"
                ></el-input>
              </div>
            </div>

            <div class="first-list">
              <div class="list-title">预留电话</div>
              <div class="list-input">
                <el-input
                  v-model="bankPhone"
                  placeholder="请输入银行卡预留手机号码"
                ></el-input>
              </div>
            </div>

            <div class="first-list">
              <div class="list-title">开户行名称</div>
              <div class="list-input">
                <el-input
                  v-model="bankSub"
                  placeholder="请输入开户行(XX银行XX省XX市XX支行）"
                ></el-input>
              </div>
            </div>

            <div class="second">
              <div class="second-title">银行卡上传</div>

              <div class="second-block">
                <div class="block-list">
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :on-change="setFile"
                    :auto-upload="false"
                  >
                    <div class="list" v-if="frontImg != ''">
                      <img class="list-imgs" :src="frontImg" alt="" />
                    </div>

                    <div class="list" v-else>
                      <img
                        class="list-img"
                        src="../assets/ic_picture.png"
                        alt=""
                      />
                      <div class="list-title">银行卡正面照片</div>
                    </div>
                  </el-upload>
                </div>

                <div class="block-list">
                  <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="reverFile"
                  >
                    <div class="list" v-if="reverseImg == ''">
                      <img
                        class="list-img"
                        src="../assets/ic_picture.png"
                        alt=""
                      />
                      <div class="list-title">银行卡反面照片</div>
                    </div>

                    <div class="list" v-else>
                      <img class="list-imgs" :src="reverseImg" alt="" />
                    </div>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>

          <div class="block-second">
            <div class="second-button">
              <div class="button-list1" @click="bindingClick()">确认绑定</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--公共底部模块-->
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from '../components/bottom.vue'
import {
  priceLog,
  statistics,
  getUserInfo,
  getBank,
  getExtract,
  getWithrawRate,
  //---------
  getnew_collaborate_Bank,
  getAllBankName,
  postnew_collaborate_bankNew,
  get_value
} from '../api/index' // 获取对应的收益明细数据 、  收益明细请求公共统计
import { formatDate } from '../utils/date'

export default {
  components: { bottom },
  filters: {
    formatDate(time) {
      time = time * 1000 //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  data() {
    return {
      // 列表条数
      limit: 10,
      // 列表页码
      page: 1,
      // 确认收货时间输入框
      receiptValue: '',
      // 时间区间
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      // 搜索弹出框
      dialogVisible: false,
      // 收益明细列表对象
      eamingObj: {},
      // 收益明细列表总条数
      totalNum: 0,

      // 时间区域 起始
      payStartTime: '',
      // 时间区域 完结
      payEndTime: '',
      // 收益明细对象
      earningObj: {},
      // 用户id
      userId: '',
      // 手机号
      userPhone: '',
      // 用户订单号
      userOrder: '',
      // 用户搜索弹出框文案
      dialodTitle: '',
      //---------切换
      earningsShow: 0,
      //   depositChecked: false,
      depositplayShow: 0,
      realName: '',
      moneyPrice: '',
      depositdialogVisible: false,
      depositdialogIndex: -1,
      //----------------
      depositdialogVisiblepay: false,

      // 支付宝选择信息合计
      zfbObj: {},
      depositIndex: -1,
      bankList: [],
      returnTitle: '',
      orderValue: '',
      bankTitle: '',
      options: [
        {
          value: 'ENTER',
          label: '已入账',
        },
        {
          value: 'WAIT',
          label: '待入账',
        },
        {
          value: 'PAST',
          label: '已过期',
        },
      ],

      //--------
      warningShow: false,
      rateTitle: '0',
      newValPrice: '',
      returnPrice: '',
      accountPrice: '0',
      depositdialogObj: {},
      //---------------支付宝
      paytotalNum: -1,
      typeIndex: -1,
      oldtypeIndex: '',
      //-------
      dialogVisible8Show: false,
      signacontractObj: {},
      signOptions: [],
      supportObj: {},
      //------
      bankValue: '',
      bankCard: '',
      bankPhone: '',
      bankSub: '',
      bankObjs: {},
      fileList: [],
      reverseList: [],
      frontImg: '',
      reverseImg: '',
      depositObj: {},
            //--------最低提款金额
            lowPrice: ''
    }
  },
  created() {
    // 获取收益明细数据
    this.getpriceLog()
    // 收益明细请求公共统计页面
    this.getstatistics()
    this.getUserInfoMet()
    // 获取支付宝
    this.getBankMet1()
    this.getAllBankNameMet()

    this.getwalletInfoMet()
       // 最低提款
       this.get_valueMet()
  },
  watch: {
    moneyPrice: {
      handler(newVal, oldVal) {
        if (this.depositIndex != -1) {
          this.newValPrice = newVal

          if (newVal == '') {
            this.rateTitle = 0
          }

          this.getWithrawRateMet(newVal)
        } else {
          this.accountPrice = newVal
        }
      },
    },
    typeIndex: {
      handler(newVal, oldVal) {
        this.oldtypeIndex = oldVal
      },
    },
  },
  methods: {
    // 最低提款金额
    get_valueMet() {
      let params = {
        key: 'zd_withdrawal_prcie'
      }

      get_value(params).then(res => {
        if(res.data.code == 200) {
          this.lowPrice = res.data.content.value
        }
      })
    },
    getwalletInfoMet() {
      let obj = JSON.parse(localStorage.getItem('walletInfo'))
      this.bankObjs = obj
    },

    getWithrawRateMet(index) {
      let params = {
        field: 'earnest_withdrawal_tax_rate',
      }

      getWithrawRate(params).then((res) => {
        if (res.data.code == 200) {
          // 判断来源 0 支付宝 1 银行卡
          if (this.depositIndex == 0) {
            this.rateTitle = Number(index) * res.data.content.value
          } else {
            let expensesPrice =
              Number(index) * res.data.content.value >= 1
                ? Number(index) * res.data.content.value
                : 1
            this.rateTitle = expensesPrice
          }

          if (this.moneyPrice > 0) {
            let price = Number(this.moneyPrice) - Number(this.rateTitle)
            this.accountPrice = price.toFixed(2)
          } else {
            this.accountPrice = 0
          }
        }
      })
    },
    // 收益明细请求
    getstatistics() {
      let params = {}
      statistics(params).then((res) => {
        if (res.data.code == 200) {
          this.earningObj = res.data.content

          let num = res.data.content.consumePrice
          this.returnPrice = res.data.content.consumePrice
          this.returnTitle = '最大可提现金额：' + num
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },
    // 用户点击查询搜索
    submitBtn() {
      // 输入框搜索值 请输入用户ID/手机号/订单号
      if (this.receiptValue != null) {
        this.payStartTime = this.receiptValue[0]
        this.payEndTime = this.receiptValue[1]
      } else {
        this.payStartTime = ''
        this.payEndTime = ''
      }

      this.getpriceLog()
    },
    // 获取收益明细数据
    getpriceLog() {
      let params = {
        uid: this.userId,
        phone: this.userPhone,
        ordernum: this.userOrder,
        payStartTime: this.payStartTime,
        payEndTime: this.payEndTime,
        status: this.orderValue,
        page: this.page,
        pageSize: this.limit,
      }

      priceLog(params).then((res) => {
        if (res.data.code == 200) {
          // 获取对应的总数
          this.totalNum = Number(res.data.content.totalNum)
          // 获取对应的数组存放
          this.eamingObj = res.data.content
          if (this.totalNum == 0) {
            if (
              this.userId != '' &&
              this.userPhone == '' &&
              this.userOrder == '' &&
              this.payStartTime == undefined &&
              this.payEndTime == undefined
            ) {
              this.dialodTitle = '抱歉，此用户不存在或不在团队中！'
              this.dialogVisible = true
            } else if (
              this.userId == '' &&
              this.userPhone == '' &&
              this.userOrder != '' &&
              this.payStartTime == undefined &&
              this.payEndTime == undefined
            ) {
              this.dialodTitle = '抱歉，此订单号不存在！'
              this.dialogVisible = true
            }
          }
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },
    // 点击关闭弹出框
    handleClose() {
      this.dialogVisible = false
    },

    // 用户点击取消
    cancelBtn() {
      this.dialogVisible = false
    },
    // 用户点击确认
    affirmBtn() {
      this.dialogVisible = false
    },
    // 获取当前页码
    handleCurrentChange(val) {
      this.page = val
      this.getpriceLog()
    },

    //--------------提现页面
    depositMet() {
      this.earningsShow = 1
    },

    depositresetMet() {
      this.moneyPrice = ''
    },

    //--------------
    dephandleClose() {
      this.depositdialogVisiblepay = false
    },
    depositcCancel() {
      this.depositdialogVisiblepay = false
    },
    // 支付宝区域-----------------
    getUserInfoMet() {
      let params = {}

      getUserInfo(params).then((res) => {
        if (res.data.code == 200) {
          this.realName = res.data.content.name
        }
      })
    },

    //确认提取区域-------------------
    depositcAffirm() {
      this.extractMet()
    },

    extractMet() {
      let params = {
        total_fee: this.moneyPrice,
        bank_id: this.zfbObj.id,
      }

      getExtract(params).then((res) => {
        if (res.data.code == 200) {
          console.log('支付宝申请提现', res)

          this.earningsShow = 2
          this.depositplayShow = 1

          if (this.depositIndex == 0) {
            this.depositdialogVisiblepay = false
          } else {
            this.depositdialogVisible = false
          }
        } else {
          this.$message.warning(res.data.msg)
        }
      })
    },
    // 银行卡------------

    //---------------新逻辑  5-27
    // 获取支付宝信息
    getBankMet1(index) {
      let params = {
        type: 3,
      }

      getnew_collaborate_Bank(params).then((res) => {
        if (res.data.code == 200) {
          this.paytotalNum = res.data.content.totalNum
          if (this.paytotalNum > 0) {
            this.zfbObj = res.data.content.list[0]
          }
        }
      })
    },
    getBankMet() {
      let params = {
        type: 0,
      }

      getnew_collaborate_Bank(params).then((res) => {
        if (res.data.code == 200) {
          if (res.data.content.totalNum == 0) {
            this.$message.warning(
              '您还未绑定提现通道，请先绑定您实名认证下银行卡卡号，绑定后再次申请提现！'
            )
            return
          }

          this.bankList = res.data.content.list
          this.depositdialogVisible = true
        }
      })
    },
    depositblockMet(index) {
      if (this.moneyPrice == '') {
        this.$message.warning('请先输入金额！')
        return
      }

      if (index == 0) {
        if (this.paytotalNum == 0) {
          this.$message.warning(
            '您还未绑定提现通道，请先绑定您实名认证下支付宝账号，绑定后再次申请提现！'
          )
          return
        }

        this.depositIndex = index
        this.getWithrawRateMet(this.moneyPrice)
        this.getBankMet1()

        // 支付宝选项点击支付
      } else if (index == 1) {
        this.$message.warning('正在开发中，请等待！')
      } else if (index == 2) {
        this.typeIndex = 0
        this.depositIndex = index
        this.getBankMet()
      }
    },
    // 弹出框
    depositdialogMet(item, index) {
      this.depositdialogIndex = index
      this.depositdialogObj = item
      this.zfbObj = item
      this.bankTitle = this.typeIndex == 0 ? item.bank_name : item.bank_number
      this.getWithrawRateMet(this.moneyPrice)
      this.depositdialogVisible = false
    },

    deposithandleClose() {
      if (this.depositIndex == 0) {
        this.depositdialogIndex = 0
        this.depositnextMet(this.depositIndex)
      } else {
        this.bankTitle = this.zfbObj.bank_name
      }
      this.getWithrawRateMet(this.moneyPrice)

      this.depositdialogVisible = false
    },

    //---------
    // 监听输入
    moneyChange(e) {
      let totalprice = e.replace(/^(0+)|[^\d]+/g, '')
      // let totalprice = e;

      if (Number(totalprice) <= Number(this.earningObj.consumePrice)) {
        this.moneyPrice = totalprice
      } else {
        this.moneyPrice = this.earningObj.consumePrice
      }
    },
    addBank() {
      this.bankValue = ''
      this.bankCard = ''
      this.bankPhone = ''
      this.bankSub = ''
      this.fileList = []
      this.reverseList = []
      this.frontImg = ''
      this.reverseImg = ''
      this.depositObj = {}

      this.dialogVisible8Show = true
    },
    handleClose8() {
      this.dialogVisible8Show = false
    },
    selectChange(e) {
      this.signOptions.forEach((item, index) => {
        if (item.bank_name == e) {
          this.depositObj = item
        }
      })
    },
    getAllBankNameMet() {
      let params = {}
      getAllBankName(params).then((res) => {
        if (res.data.code == 200) {
          this.signOptions = res.data.content
        }
      })
    },
    //--------上传图片
    setFile(file, fileList) {
      this.fileList = fileList
      // 正面照片
      const formData = new FormData()
      formData.append('files', file.raw)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.$http
        .post('https://api.xmw1688.cn/api/login/shanchuan', formData, config)
        .then((res) => {
          if (res.data.code == 200) {
            let fileUrl = 'https://oss.xmw1688.cn' + res.data.content
            this.frontImg = fileUrl
            // 正面图片
            console.log('出发', this.frontImg)
          }
        })
    },

    // element 上传
    reverFile(file, fileList) {
      this.reverseList = fileList
      // 正面照片
      const formData = new FormData()
      formData.append('files', file.raw)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.$http
        .post('https://api.xmw1688.cn/api/login/shanchuan', formData, config)
        .then((res) => {
          if (res.data.code == 200) {
            let fileUrl = 'https://oss.xmw1688.cn' + res.data.content
            this.reverseImg = fileUrl
            // 背图片
          }
        })
    },
    bindingClick() {
      let params = {
        bank_type_id:
          this.depositObj.bank_name == undefined ? '0' : this.depositObj.id,
        bank_number: this.bankCard, // 银行卡号
        bank_name: this.bankValue, // 银行名
        phone: this.bankPhone, // 预留电话
        bank_branch_name: this.bankSub, // 支行
        front_img: this.frontImg, // 正面
        back_img: this.reverseImg, // 反面
      }

      postnew_collaborate_bankNew(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('提交成功！')
          this.getBankMet()
          this.dialogVisible8Show = false
        }
      })
    },

    depositnextMet() {
      if (this.moneyPrice == '') {
        this.$message.warning('请输入金额！')
        return
      }

      if (this.warningShow == false) {
        this.$message.warning('请勾选支付协议！')
        return
      }

      if (Number(this.moneyPrice) < Number(this.lowPrice)) {
        this.$message.warning(`提现金额不能少于${this.lowPrice}元，请重新输入`)
        return
      }

      if(this.depositIndex == -1) {
        this.$message.warning('请先勾选提现方式！')
        return;
      }

      let params = {
        total_fee: this.moneyPrice,
        bank_id: this.zfbObj.id,
      }

      getExtract(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)

          this.earningsShow = 2
          this.depositplayShow = 1
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-date-editor .el-range-input {
  font-size: 14px !important;
}
</style>

<style lang="less" scoped>
.earnings {
  margin: 20px;

  .earnings-interface {
    .earnings-headline {
      .headline-block {
        width: 100%;
        height: 142px;
        margin-top: 5px;
        background: #ffffff;
        display: flex;

        .headline-block-left {
          width: calc(100% * 0.9);
          height: 100%;
          display: flex;
          padding: 0 7px;
          box-sizing: border-box;

          border-radius: 5px;

          .headline-block-left-div {
            width: calc((100% / 2));
            height: 100%;
            align-items: center;
            display: flex;
            justify-content: center;

            .headline-block-left-div0 {
              padding: 10px 31px 0;
              box-sizing: border-box;
              height: 98px;
              width: 100%;

              .block-left-div0-top {
                height: 26px;
                display: flex;
                align-items: center;

                .block-left-div0-top-img {
                  width: 26px;
                  height: 26px;
                }

                .block-left-div0-top-title {
                  margin-left: 14px;
                  font-size: 14px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #333333;
                }
              }

              .block-left-div0-bottom {
                margin-top: 10px;

                .block-left-div0-bottom-price {
                  font-size: 1.5rem;
                  font-family: DINAlternate-Bold, DINAlternate;
                  font-weight: bold;
                  color: #29d647;
                }
              }
            }

            .headline-block-left-div1,
            .headline-block-left-div2 {
              height: 98px;
              background: #f7f7f7;
              border-radius: 4px;
              width: 100%;
              padding: 19px 21px 0;
              box-sizing: border-box;

              .block-left-div1-top {
                display: flex;
                height: 30px;
                align-items: center;

                .block-left-div1-top-text {
                  height: 20px;
                  font-size: 14px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #222222;
                  line-height: 20px;
                }

                .block-left-div1-top-price {
                  height: 30px;
                  font-size: 26px;
                  font-family: DINAlternate-Bold, DINAlternate;
                  font-weight: bold;
                  color: #222222;
                  line-height: 30px;
                  margin-left: 7px;
                }

                .block-left-div1-top-rise {
                  width: 18px;
                  height: 19px;
                  margin-left: 11px;
                }
              }

              .block-left-div1-bottom {
                display: flex;
                align-items: center;
                margin-top: 10px;
                justify-content: space-between;

                .block-left-div1-bottom-left {
                  font-size: 14px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 600;
                  color: #222222;
                }

                .block-left-div1-bottom-right {
                  font-size: 14px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 600;
                  color: #222222;
                }
              }
            }

            .headline-block-left-div2 {
              background: #fff;
            }
          }
        }

        .headline-block-right {
          margin-left: 10px;
          width: calc(100% * 0.4);
          height: 100%;
          background: linear-gradient(125deg, #084212 0%, #0e1924 100%);
          padding: 26px;
          box-sizing: border-box;
          display: flex;
          border-radius: 5px;
          justify-content: space-between;
          border: 1px solid;

          .headline-block-right-queer {
            .block-right-queer-title {
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
            }

            .block-right-queer-price {
              margin-top: 21px;
              font-size: 26px;
              font-family: DINAlternate-Bold, DINAlternate;
              font-weight: bold;
              color: #ffffff;
            }
          }

          .headline-block-right-dextro {
            position: relative;

            .block-right-dextro-withdraw {
              width: 71px;
              height: 30px;
              background: rgba(41, 214, 71, 0.21);
              border-radius: 4px;
              border: 1px solid rgba(41, 214, 71, 0.27);
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              position: absolute;
              right: 0;
              cursor: pointer;
            }

            .block-right-dextro-div {
              display: flex;
              margin-top: 54px;

              .dextro-div-usable,
              .dextro-div-lock {
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #d9d9d9;
              }

              .dextro-div-lock {
                margin-left: 21px;
              }
            }
          }
        }
      }

      .headline-third {
        width: 100%;
        background: #fff;
        margin-top: 10px;
        padding: 24px;
        box-sizing: border-box;

        .headline-third-top {
          height: 40px;
          display: flex;
          align-items: center;
          width: 100%;

          .headline-third0 {
            margin-right: 30px;
            cursor: pointer;
            display: flex;
            height: 40px;
            align-items: center;

            .headline-third0-title {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-right: 10px;
            }

            /deep/ .el-input--suffix .el-input__inner {
              padding-right: 15px;
            }

            /deep/ .el-input {
              width: 170px;
            }

            /deep/ .el-input__inner {
              height: 32px;
              line-height: 32px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            }

            /deep/ .el-input__icon {
              line-height: 32px;
            }
          }

          .headline-third1 {
            display: flex;
            height: 40px;
            align-items: center;

            .headline-third1-title {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-right: 10px;

              .prefix-icon-class {
                display: none;
              }
            }

            /deep/ .el-date-editor .el-range-separator {
              width: 40px;
              line-height: 27px;
              font-size: 14px;
              display: flex;
              align-items: center;
            }

            /deep/ .el-input__inner {
              height: 32px;
            }

            /deep/ .el-input__icon {
              line-height: 25px;
            }

            /deep/ .el-input__inner {
              width: 280px;
            }
          }
        }

        .headline-third-bottom {
          display: flex;
          height: 40px;
          align-items: center;
          margin-top: 10px;
          justify-content: flex-start;

          .headline-third1-bottom {
            cursor: pointer;
            width: 60px;
            height: 32px;
            background: #29d647;
            border-radius: 5px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 32px;
            text-align: center;
          }
        }
      }

      .headline-table {
        cursor: pointer;

        /deep/ .el-table th.el-table__cell > .cell {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #565656;
        }

        /deep/ .el-table {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #717171;
        }

        padding: 0 24px;
        box-sizing: border-box;
        background: #fff;

        .earningStatusColor {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
      }

      .headline-paging {
        height: 102px;
        width: 100%;
        background: #ffffff;
        padding-right: 30px;

        /deep/ .el-pagination {
          display: flex;
          justify-content: flex-end;
          padding-top: 20px;
        }

        /deep/ .el-pager li {
          width: 32px;
          height: 32px;
          padding: 0;
          line-height: 32px;
          margin-right: 8px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
        }

        /deep/ .el-pager li.active {
          background: #eafbed;
          border-radius: 4px;
          color: #29d647;
        }

        /deep/ .el-pager li:hover {
          color: #29d647;
        }

        /deep/ .el-pagination .btn-prev {
          width: 32px;
          height: 32px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          margin-right: 8px;
        }

        /deep/.el-pagination .btn-next {
          width: 32px;
          height: 32px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          margin-right: 8px;
        }

        /deep/ .el-pagination button:hover {
          color: #29d647;
        }
      }
    }
  }

  .earnings-deposit {
    background: #ffffff;
    padding: 0 24px;
    padding-bottom: 50px;
    box-sizing: border-box;

    .deposit-first {
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .first-right {
        display: flex;
        align-items: center;

        .right-sign {
          width: 26px;
          height: 26px;
          background: #29d647;
          border-radius: 21px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }

        .right-title {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-left: 10px;
        }

        .right-lint {
          width: 14px;
          height: 14px;
          margin: 0 12px;
          display: flex;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .deposit-second {
      .second-name {
        display: flex;
        // align-items: center;
        margin-bottom: 20px;

        .name {
          width: 70px;
          text-align: left;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          height: 50px;
          line-height: 50px;

          span {
            color: #ff6a20;
          }
        }

        .input {
          height: 50px;
          margin-left: 18px;
          width: 320px;
          position: relative;
          display: flex;
          align-items: center;

          /deep/ .el-input__inner {
            height: 50px;
            font-size: 14px;
            padding: 0 40px 0 14px;
            box-sizing: border-box;
            width: 320px;
            border-radius: 0;
          }

          .price {
            font-size: 14px;
            position: absolute;
            right: 14px;
            margin-top: -4px;
            color: #c0c4cc;
            font-weight: 500;
          }
        }

        .text {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin-top: 10px;
          margin-left: 18px;
        }

        .block {
          display: flex;
          margin-left: 18px;

          .block-list {
            width: 134px;
            height: 50px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 0.5px solid #ccc;

            img {
              height: 50px;
              object-fit: contain;
            }

            .block-list-title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 15px;
              margin-left: 10px;
              color: #222222;
            }
          }

          .active {
            border: 2px solid #29d647;
          }
        }
      }

      .second-display {
        display: flex;
        margin: 22px 0;

        .display-lint {
          width: 70px;
          margin-right: 18px;
        }

        .display-text {
          margin-right: 60px;
          font-size: 14px;
          text-align: left;
          color: #757575;
        }
      }

      .second-bottom {
        margin-top: 22px;
        display: flex;
        align-items: center;
        margin-left: 87px;

        .bottom-1,
        .bottom-2 {
          width: 126px;
          height: 38px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          text-align: center;
          line-height: 38px;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.65);
          cursor: pointer;
        }

        .bottom-2 {
          background: #29d647;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-left: 20px;
        }

        .protocol {
          color: #a3a3a3;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
        }

        /deep/ .el-checkbox__inner {
          margin-top: 1px;
        }

        /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #29d647;
          border-color: #29d647;
        }

        /deep/ .el-checkbox__inner:hover {
          border-color: #29d647;
        }

        /deep/ .is-checked::after {
          background-color: #29d647;
        }

        /deep/ .is-focus::after {
          background-color: #29d647;
        }
      }

      .second-warning {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #a3a3a3;
        margin-top: 20px;
        display: flex;
        align-items: center;
        margin-left: 88px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }

  .earnings-approve {
    background: #ffffff;
    padding: 10px 24px 0 40px;
    padding-bottom: 50px;
    box-sizing: border-box;

    .approve-first {
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .first-right {
        display: flex;
        align-items: center;

        .right-sign {
          width: 26px;
          height: 26px;
          background: #29d647;
          border-radius: 21px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }

        .right-title {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-left: 10px;
        }

        .right-lint {
          width: 14px;
          height: 14px;
          margin: 0 12px;
          display: flex;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .approve-result {
      height: 100px;
      border-bottom: 1px solid #dfdfe0;
      padding: 0 34px 0 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .result-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .result-block {
        margin-left: 12px;
        text-align: left;

        .block-title {
          font-size: 26px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #333333;
        }

        .block-text {
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #ff6a20;
          margin-top: 5px;
        }
      }
    }

    .approve-block {
      padding: 60px 0;
      box-sizing: border-box;

      .block {
        display: flex;
        margin-bottom: 20px;

        .block-left {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #999999;
          width: 80px;
          text-align: right;
        }

        .block-right {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #3a3a3a;
          margin-left: 18px;
        }
      }

      .uploading {
        display: flex;
        align-items: normal;

        .uploading-name {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #999999;
          text-align: left;
          margin-right: 18px;
        }

        .uploading-list {
          width: 182px;
          height: 116px;
          border: 1px solid;
          margin-right: 20px;
        }
      }
    }
  }

  .depositblock {
    /deep/ .el-dialog {
      width: 580px !important;
    }

    /deep/ .el-dialog__header {
      text-align: center;
    }

    /deep/ .el-dialog__title {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #3a3a3a;
    }

    /deep/ .el-dialog__body {
      padding: 0 20px 20px;
      box-sizing: border-box;
    }

    .dialog-block {
      overflow: auto;
      padding: 0 10px 0 0;
      height: 40vh;
      .block-list {
        width: 100%;
        height: 57px;
        margin-bottom: 10px;
        padding: 16px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        cursor: pointer;

        .list-left {
          display: flex;

          img {
            width: 21px;
            height: 21px;
            margin-right: 16px;
          }

          .left-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #222222;
          }
        }

        .list-right {
          width: 18px;
          img {
            width: 18px;
          }
        }
      }
    }

    /* 全局样式 */
    .dialog-block::-webkit-scrollbar {
      width: 6px;
      /* 设置滚动条宽度 */
    }

    /* 滑道部分 */
    .dialog-block::-webkit-scrollbar-track {
      background-color: #f5f5f5;
      /* 设置滑道背景色 */
    }

    /* 滑块部分 */
    .dialog-block::-webkit-scrollbar-thumb {
      background-color: #999;
      /* 设置滑块背景色 */
      border-radius: 4px;
      /* 设置滑块边角半径 */
    }

    /* 当悬停时显示的效果 */
    .dialog-block::-webkit-scrollbar-thumb:hover {
      background-color: #666;
      /* 设置滑块悬停时的背景色 */
    }

    .dialog-bottom {
      width: 278px;
      height: 44px;
      background: #29d647;
      border-radius: 5px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      cursor: pointer;
      color: #ffffff;
      margin-top: 30px;
      border: 1px solid;
    }

    .block-display {
      .img {
        width: 44px;
        height: 44px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .title {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin: 16px 0 7px;
        text-align: center;
      }

      .text {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
      }

      .bottom {
        display: flex;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .bottom-left,
        .bottom-right {
          width: 100px;
          height: 34px;
          background: #ffffff;
          border-radius: 17px;
          border: 1px solid #eeeeee;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .bottom-right {
          color: #ffffff;
          background: #29d647;
          margin-left: 20px;
        }
      }
    }
  }

  .earning-dialog {
    /deep/ .el-dialog {
      height: 248px;
    }

    /deep/ .el-dialog__header {
      padding: 0;
    }

    .earning-dialog-div {
      text-align: center;

      .earning-dialog-div-img {
        width: 49px;
        height: 49px;
        margin: 0 auto;
      }

      .earning-dialog-div-title {
        height: 32px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 32px;
        margin-top: 5px;
      }

      .earning-dialog-div-display {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 44px;

        .earning-dialog-div-display-cancel,
        .earning-dialog-div-display-affirm {
          width: 88px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          line-height: 32px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          cursor: pointer;
        }

        .earning-dialog-div-display-affirm {
          margin-left: 8px;
          background: #29d647;
          color: #ffffff;
        }
      }
    }
  }

  .mysign {
    /deep/ .el-dialog__header {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #3a3a3a;
      text-align: center;
    }

    .mysign-block {
      .block-first {
        .first-list {
          margin-bottom: 10px;

          .list-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #333333;
            margin-bottom: 5px;
          }
          .list-input {
            display: flex;
            align-items: center;
            position: relative;

            /deep/ .el-input__inner {
              height: 44px;
              line-height: 44px;
              font-size: 14px;
            }

            ::v-deep .el-input input::-webkit-input-placeholder {
              color: #c0c4cc;
              font-size: 14px;
            }

            ::v-deep .el-input input::-moz-input-placeholder {
              color: #c0c4cc;
              font-size: 14px;
            }

            ::v-deep .el-input input::-ms-input-placeholder {
              color: #c0c4cc;
              font-size: 14px;
            }

            /deep/ .el-input__icon {
              height: 44px;
              line-height: 44px;
            }

            /deep/ .el-select {
              width: 100%;
            }

            /deep/ .el-date-editor.el-input {
              width: 100%;
            }

            .input-div {
              width: 150px;
              height: 44px;
              background: #ffffff;
              border-radius: 5px;
              border: 1px solid #29d647;
              text-align: center;
              line-height: 44px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #29d647;
              margin-left: 10px;
              cursor: pointer;
            }

            .first-righ {
              position: absolute;
              right: 40px;
              z-index: 10;
              font-size: 14px;
              color: #999999;
            }
          }
        }

        .first-text {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ff7070;
          margin-top: 17px;
        }

        .second {
          .second-title {
            text-align: left;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #222222;
            margin-bottom: 18px;
          }

          .second-block {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .block-list {
              width: 50%;
              height: 109px;
              background: #ffffff;
              border-radius: 10px;
              border: 1px dashed #979797;
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              /deep/ .avatar-uploader {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              /deep/ .el-upload {
                width: 100%;
              }

              .list {
                text-align: center;
                .list-img {
                  width: 41px;
                  height: 41px;
                  margin-bottom: 5px;
                }

                .list-title {
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 12px;
                  color: #222222;
                }

                .list-imgs {
                  width: 100%;
                  height: 109px;
                  border-radius: 10px;
                }
              }
            }

            .block-list:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .block-second {
        margin-top: 40px;
        .second-button {
          display: flex;
          justify-content: flex-end;

          .button-list0 {
            width: 126px;
            height: 38px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 38px;
            text-align: center;
            cursor: pointer;
          }

          .button-list1 {
            width: 80vw;
            height: 50px;
            background: #29d647;
            border-radius: 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 50px;
            margin-left: 12px;
            cursor: pointer;
          }
        }

        .second-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #a3a3a3;
          text-align: right;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
